


























import {Vue, Component} from "vue-property-decorator";
import GuessBoxAdminType from "@/views/Other/GuessBoxAdmin/GuessBoxAdmin";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import ZoomPage from "@/impView/PageSafety";
import CreatePlay from "@/views/Other/GuessBoxAdmin/components/CreatePlay/index.vue";
import JoinPersonSuc from "@/views/Other/GuessBoxAdmin/components/JoinPersonSuc/index.vue";
import BoxList from "@/views/Other/GuessBoxAdmin/components/BoxList/index.vue";
import LotterySuc from "@/views/Other/GuessBoxAdmin/components/LotterySuc/index.vue";
import {getPlayData, updatePlayStatus} from './Server';
import {Toast} from "vant";

@Component({name: "GuessBoxAdmin",components:{ HeadTop,CreatePlay,JoinPersonSuc,BoxList,LotterySuc }})
export default class GuessBoxAdmin extends ZoomPage implements GuessBoxAdminType{
    data:any = {}
    isGame = false
    codeCouponData:any = {}

    mounted() {
        this.handleUpData()
    }

    handleGetCouponData(e:any){
        this.codeCouponData = e;
    }

    handleUpData(bool=false){
        getPlayData(bool).then(res=>{
            if ( res ){
                this.data = res
                this.isGame = true
            }else
                this.isGame = false;
        })
    }

    handleClickBtn() {
        let {status = 0, id = ""} = this.data
        let { code = "",data = {} } = this.codeCouponData
        let { id:couponId = "" } = data
        switch (status | 0) {
            case 0:
                updatePlayStatus({id, status: 1}).then(res => {
                    this.handleUpData()
                })
                break;
            case 1:
                updatePlayStatus({id, status: 2}).then(res => {
                    this.handleUpData()
                })
                break;
            case 2:
                if ( !code ) {
                    Toast("请输入中奖号");
                    return;
                }
                if ( !couponId ){
                    Toast("请选择发放优惠卷");
                    return;
                }
                updatePlayStatus({id, status: 2,prizeNum:code,couponId}).then(res => {
                    this.handleUpData()
                })
                break
        }
    }

    get getBtnTxt(){
        let { status = 0 } = this.data
        switch (status | 0) {
            case 0:
                return "开始";
            case 1:
                return "结束投票";
            case 2:
                return "确定发放"; // 发放优惠卷
            case 3:
                return "开始下一轮";
            default:
                return "确定"
        }
    }
}
