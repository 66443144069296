

















import {Vue, Component, Prop} from "vue-property-decorator";
import BoxItemType from './indexType';

@Component({name: "BoxItem"})
export default class BoxItem extends Vue implements BoxItemType{
    rightIcon = require("@/assets/icon/Basics/Right.png")

    handleToPath(){
        let { boxId = "",boxCount = "" } = this.getData
        this.$router.push({
            name:"guessJoinList",
            query:{
                id:this.getData.id,
                key:boxId,
                state:"look",
                title:boxId + '号盲盒'
            }
        })
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }
}
