


















import {Vue, Component} from "vue-property-decorator";

@Component({name: "index.vue"})
export default class CreatePlay extends Vue {
}
