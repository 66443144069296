






















import {Vue, Component, Watch, Prop} from "vue-property-decorator";
import JoinPersonSucType from "@/views/Other/GuessBox/components/Head/indexType";
import moment from "moment";
import {GuessBoxAdminStore} from "@/views/Other/GuessBoxAdmin/Store";

@Component({name: "JoinPersonSuc"})
export default class JoinPersonSuc extends Vue implements JoinPersonSucType{
    data:any={}


    get getData(){
        return GuessBoxAdminStore.getGuessBoxAdminData
    }
    @Watch("getData")
    changeData(newVal:any){
        this.data = newVal
    }

    get getTime(){
        if ( this.data && this.data.startTime ){
            return moment(this.data.startTime).format("YYYY-MM-DD HH:mm")
        }else{
            return moment().format("YYYY-MM-DD HH:mm")
        }
    }
}
