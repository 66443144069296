










import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import InputVal from "@/components/ShareComponent/InputVal/index.vue"
import Storage from "@/util/Storage"

@Component({name: "LotterySuc",components:{ InputVal }})
export default class LotterySuc extends Vue {
    rightIcon = require("@/assets/icon/Basics/Right.png")
    lottery = ""
    couponVal = ""
    couponData = {}

    mounted(){
        this.init()
        this.initData()
    }

    activated() {
        this.init()
    }

    init(){
        let selectData = Storage.GetData_?.("adminSelectCoupon");
        if ( selectData != null ){
            let parseJoin:any = {};
            try{
                parseJoin = JSON.parse(selectData as string)
                this.couponData = parseJoin;
                let { name = "",amount = "" } = parseJoin;
                this.couponVal = name + " · 优惠 " + amount + "元"
            }catch (e) {
                console.info("装换失败")
            }
        }
    }

    initData(){
        if ( JSON.stringify(this.getData) != "{}" ){
            let { status = "0" } = this.getData
            if ( status == 2 ) Storage.DelData_("adminSelectCoupon");
        }
    }

    handleGetValue(e){
        let { data = "",other = {} } = e
        let { state = "" } = other
        if ( state )this[state] = data;
    }

    handleClick(e){
        this.$router.push({
            name:"adminCouponList",
            query:{
                state:"select"
            }
        })
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data || {}
    }
    @Watch("getData")
    changeData(newVal:any){
        this.initData()
    }

    @Watch("couponData")
    @Watch("lottery")
    @Emit("pullCouponData")
    pullCouponData(){
        return {
            data:this.couponData,
            code:this.lottery
        }
    }
}
