
















import {Vue, Component, Watch} from "vue-property-decorator";
import BoxItem from "@/views/Other/GuessBoxAdmin/components/BoxItem/index.vue";
import { GuessBoxAdminStore } from '../../Store';

@Component({name: "BoxList",components:{ BoxItem, }})
export default class BoxList extends Vue {
    boxIcon = require("../../Img/box.png")

    get getList(){
        return GuessBoxAdminStore.getGuessBoxAdminList || []
    }

}
